.document-settings .ant-form,
.document-settings .ant-form .ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.document-settings .ant-form .text-processing-rules {
  margin-bottom: 14px;
}

.document-settings .ant-form .text-processing-rules .ant-form-item {
  margin-bottom: 0px;
}

.document-settings .ant-form .ant-input-number {
  width: 100%;
}

.document-settings .ant-form button[type='submit'] {
  width: 100%;
}

