.main-layout {
  height: 100vh;
}

.main-layout .mini-logo {
  margin: 25px auto;
  width: 35px;
}

.main-layout .mini-logo img {
  width: 35px;
}

.main-layout-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  min-height: 280px;
  padding: 20px;
  overflow: auto;
}

.main-layout .ant-layout-sider-trigger {
  background-color: #38304d;
}

.main-layout-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  padding-bottom: 0;
  overflow: auto;
  gap: 12px;
}

.navigation {
  display: flex;
  justify-content: space-between;
}
