.start-node {
}

.start-node-sidebar {
  &-settings {
    &-document {
      &-max-file-size {
        max-width: 40%;
      }
      &-max-file-characters {
        max-width: 40%;
      }
    }
  }
}
