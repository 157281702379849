.row-title {
  min-width: 90px;
  margin: auto 0;
}

.row-input {
  flex: 1;
  margin: auto 0;
  overflow: hidden;

  :global {
    .ant-select-selection-item > * {
      overflow: hidden;
    }
  }
}
