.agent-list .agent-card {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 210px;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
  margin: 20px;
  margin-left: 0;
  margin-bottom: 0;
}

.agent-card:hover {
  box-shadow: 0 4px 8px #eee;
}

.agent-card .agent-type {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 12px;
  color: #393349;
}

.agent-card .agent-name {
  font-size: 16px;
  font-weight: bolder;
  padding: 5px 0;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.agent-card .agent-description {
  flex: 1;
  text-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.agent-card .agent-button {
  text-align: center;
}

.agent-card .agent-button .ant-btn-primary {
  width: 120px;
}
