.radio-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border: 1px black;
  gap: 20px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background: #f5f5f5;
  height: min-content;
  width: 100%;
  text-align: start;
  text-wrap: wrap;
}

.radio-card .radio-card-header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

.radio-card .radio-card-header > *:nth-child(2) {
  flex: 1;
}

.radio-card .radio-card-header > *:nth-child(2) > h3 {
  margin-top: 0;
  margin-bottom: 6px;
}

.radio-card:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.radio-card:has(.radio-card-header .ant-radio.ant-radio-checked) {
  border-color: cornflowerblue;
  background-color: #fbfbfb;
}

.radio-card .radio-card-body {
  display: none;
  width: 100%;
}

.radio-card .radio-card-body > * {
  flex: 1;
}

.radio-card
  .radio-card-header:has(.ant-radio.ant-radio-checked)
  ~ .radio-card-body {
  display: flex;
  flex-direction: column;
}
