.ant-modal.two-factor-auth .ant-steps .ant-steps-item-description {
  padding-top: 16px;
  padding-bottom: 24px;
}

.ant-modal.two-factor-auth
  .ant-steps
  .ant-steps-item-description
  span
  > button {
  margin-top: 16px;
}
