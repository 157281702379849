.publish-channel {
  &-wrapper {
    position: absolute;
    top: 20px;
    width: 450px;
    height: 100%;
  }

  &-panel-wrapper {
    height: 90%;
  }

  &-panel-navbar {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 8px;
    }

    &-left {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }
}
