.condition-node {
  width: 250px;

  :global {
    .node-conditions {
      .condition-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 10px;
        border-radius: 4px;
        background-color: #eee;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .react-flow__handle {
        position: relative;
        left: 25px;
      }
    }
  }
}
