.header-tabs {
  &-wrapper {
    :global {
      .ant-tabs-nav::before {
        display: none;
      }

      .ant-tabs-tab {
        padding: 10px;
        border-radius: 8px;
        background-color: #fff;

        & a {
          color: #38304d;
        }
      }

      .ant-tabs-tab-active {
        background-color: #eee;
      }
    }
  }
}
