.intent-editor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.intent-editor .dataset .dataset-list {
  width: 100%;
}

.intent-editor .dataset .dataset-list .dataset-title-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.intent-editor .dataset .dataset-list .dataset-title-box .dataset-title {
  font-size: 18px;
  line-height: 32px;
  height: 32px;
}

.intent-editor .dataset .dataset-list .dataset-list-items {
  padding: 10px 0;
}
