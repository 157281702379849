.tool-menu {
  background-color: white;
  border-radius: 8px;
  border: unset !important;
  height: 300px;
  overflow: auto;

  :global {
    .ant-menu {
      background-color: transparent;
      border-right: none;
      padding: 0;
      margin: 0;
    }

    .ant-menu-vertical {
      border: 0;
    }

    .ant-menu-item {
      height: 40px;
      line-height: 40px;
    }

    .ant-menu-item-group-title {
      padding: 8px 16px;
      color: rgba(0, 0, 0, 0.45);
    }

    .ant-menu {
      background-color: transparent;
      border-right: none;
      padding: 0;
      margin: 0;
      width: 220px !important;
    }

    .ant-menu-vertical {
      border: 0;
    }

    .ant-menu-vertical,
    .ant-menu-vertical-left,
    .ant-menu-vertical-right {
      border-right: none !important;
    }

    .active {
      display: block;
    }

    .ant-menu-item {
      height: 40px;
      line-height: 40px;
    }

    .ant-menu-item-group-title {
      padding: 8px 16px;
      color: rgba(0, 0, 0, 0.45);
    }

    .ant-menu,
    .ant-menu-sub,
    .ant-menu-inline {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
