.create-edit-channel {
  &-wrapper {
    display: contents;
  }

  &-modal {
    &-wrapper {
      height: 90%;
    }

    &-content {
      margin: 8px 0;
      padding: 0 12px 0 8px;
    }
  }

  &-navbar {
    &-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 8px;
    }

    &-left {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &-label-wrapper {
    padding-block-end: 8px;
  }

  &-item-wrapper {
    margin-block-end: 24px;
  }
}
