.workflow {
  /* margin: 20px 0; */
  margin-top: 0;
}

.workflow-canvas {
  position: relative;
  height: calc(100vh - 146px);
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  padding: 20px;
}

.workflow-canvas .custom-node {
  padding: 15px;
  padding-top: 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.workflow-canvas .custom-node .custom-node-delete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  right: -10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.workflow-canvas .custom-node .node-section {
  background-color: #f7f7f7;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  margin: 10px 0;
  position: relative;
}
.workflow-canvas .custom-node .node-section .subtitle {
  padding: 10px 0;
}
.workflow-canvas .custom-node .node-section select {
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  padding: 0 11px;
}

.workflow-canvas .custom-node .node-section select:hover {
  border-color: #565366;
}
.workflow-canvas .workflow-toolbar {
  width: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 250px;
  margin-left: 30px;
  height: calc(100vh - 500px);
  display: flex;
  flex-direction: column;
}

.workflow-toolbar-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.workflow-toolbar-submit {
  padding: 10px;
}

.react-flow__attribution {
  display: none;
}
.workflow-canvas .custom-node .node-operation {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
}
.workflow-canvas .custom-node .node-operation .ant-btn {
  border: 0;
  box-shadow: none;
}
.workflow-canvas .custom-node .condition-handler {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(-50%, -50%);
  margin-left: 20px;
}

.workflow-canvas .custom-node .react-flow__handle {
  /* width: 10px;
  height: 10px;
  border: 2px solid #eee;
  transition:
    width 0.3s ease,
    height 0.3s ease; */
}

.workflow-canvas .custom-node:hover .react-flow__handle {
  /* width: 13px;
  height: 13px; */
}
.workflow-canvas .intent-detector {
  width: 600px;
}
.workflow-canvas .intent-detector .else-branch {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.workflow-canvas .intent-detector .onerror-branch {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
  border: 1px dashed red;
}
.workflow-canvas .intent-detector .condition-handler {
  right: -50px;
}
.workflow-canvas .knowledge-node {
  width: 600px;
}
.workflow-canvas .api-node {
  width: 600px;
}

.workflow-canvas .code-interpreter-node {
  width: 800px;
}
.workflow-canvas .custom-node-sidebar {
  position: absolute;
  width: 450px;
  height: calc(100vh - 146px);
  margin-top: 125px;
  margin-right: 20px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding-top: 95px;
}
.workflow-canvas .custom-node-sidebar .custom-node-sidebar-title {
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
  position: fixed;
  top: 124px;
  right: 25px;
  width: 440px;
  background-color: #fff;
  z-index: 10000;
  border-radius: 20px;
}
.custom-node-sidebar-title-left {
  width: 260px;
  display: flex;
}
.custom-node-sidebar-title-left .icon {
  width: 24px;
  height: 24px;
  background-color: #403757;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  align-content: center;
  margin-left: 8px;
}

.workflow-canvas .custom-node:hover .custom-node-delete {
  opacity: 1;
}
.workflow-canvas .active {
  border: 2px solid #000;
}
.workflow-canvas .custom-node .node-title {
  padding: 15px 0;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.workflow-canvas .custom-node .node-title .icon {
  width: 24px;
  height: 24px;
  background-color: #403757;
  align-content: center;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}
.workflow-canvas .custom-node .node-title .text {
  display: block;
  margin: 0;
  padding: 0 10px;
  font-size: 16px;
}
.workflow-canvas .custom-node .node-desc {
  font-size: 12px;
  text-align: left;
}
.workflow-canvas .start-node {
  width: 250px;
  text-align: center;
}
.workflow-canvas .end-node {
  width: 250px;
}

.workflow-canvas .custom-node .node-section {
  background-color: #f7f7f7;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  margin: 10px 0;
  position: relative;
}
.workflow-canvas .custom-node .node-section .subtitle {
  padding: 10px 0;
}
.workflow-canvas .custom-node .node-section select {
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  padding: 0 11px;
}

.workflow-canvas .custom-node .node-section select:hover {
  border-color: #565366;
}
.workflow-canvas .workflow-toolbar {
  width: 200px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  top: 250px;
  margin-left: 30px;
  height: calc(100vh - 500px);
  display: flex;
  flex-direction: column;
}

.workflow-toolbar-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.workflow-toolbar-submit {
  padding: 10px;
}

.react-flow__attribution {
  display: none;
}
.workflow-canvas .custom-node .node-operation {
  position: absolute;
  right: 0;
  top: 0;
  margin: 20px;
}
.workflow-canvas .custom-node .node-operation .ant-btn {
  border: 0;
  box-shadow: none;
}

.workflow-canvas .custom-node .condition-handler {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(-50%, -50%);
  margin-left: 20px;
}

/* .workflow-canvas .custom-node .react-flow__handle {
  width: 10px;
  height: 10px;
  border: 2px solid #eee;
  transition:
    width 0.3s ease,
    height 0.3s ease;
} */

/* .workflow-canvas .custom-node:hover .react-flow__handle {
  width: 12px;
  height: 12px;
} */
.workflow-canvas .intent-detector {
  width: 600px;
}
.workflow-canvas .intent-detector .else-branch {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.workflow-canvas .intent-detector .onerror-branch {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
  border: 1px dashed red;
}
.workflow-canvas .intent-detector .condition-handler {
  right: -50px;
}
.workflow-canvas .knowledge-node {
  width: 600px;
}
.workflow-canvas .api-node {
  width: 600px;
}

.workflow-canvas .code-interpreter-node {
  width: 800px;
}
.workflow-canvas .custom-node-sidebar {
  position: absolute;
  width: 450px;
  height: calc(100vh - 146px);
  margin-top: 125px;
  margin-right: 20px;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.workflow-canvas .custom-node-sidebar .custom-node-sidebar-title {
  padding: 15px 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.custom-node-sidebar-title-left {
  width: 260px;
  display: flex;
}
.custom-node-sidebar-title-left .icon {
  width: 24px;
  height: 24px;
  background-color: #403757;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  align-content: center;
}

.custom-node-sidebar-title-left .text {
  display: block;
  margin: 0;
  font-size: 16px;
}
.custom-node-sidebar-title-right {
  width: 200px;
  text-align: right;
}

.custom-node-sidebar-desc {
  padding: 10px 0;
}

.custom-node-sidebar {
  padding: 16px;
}

.custom-node-sidebar-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-node-sidebar-title-left {
  display: flex;
  align-items: center;
}

.custom-node-sidebar-title-left .icon {
  margin-right: 8px;
}

.editable-label,
.editable-description {
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s;
}

.editable-label {
  font-weight: 500;
}

.editable-description {
  font-size: 14px;
  width: 100%;
  resize: none;
}

.editable-label:not(:focus),
.editable-description:not(:focus) {
  cursor: pointer;
}

.editable-label:hover:not(:focus),
.editable-description:hover:not(:focus) {
  background-color: rgba(0, 0, 0, 0.05);
}

.editable-label:focus,
.editable-description:focus {
  background-color: white;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.custom-section {
  margin-bottom: 10px;
}
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 8px;
  background-color: #f3f3f38c;
  border-radius: 10px;
}

.section-header h3 {
  margin: 0;
}

.section-content {
  padding-top: 8px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.opening-questions {
  margin-top: 16px;
}

.opening-questions h4 {
  margin-bottom: 8px;
}

.question-input {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.question-input .ant-input {
  flex-grow: 1;
  margin-right: 8px;
}

.question-input .ant-btn {
  flex-shrink: 0;
}

.opening-questions .ant-btn-dashed {
  margin-top: 8px;
}

.edge-button-container {
  opacity: 1;
  transition: opacity 0.3s ease;
  text-align: center;
}

.react-flow__edge-custom:hover .edge-button-container {
  opacity: 1;
}

.tool-node {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 230px;
  height: 300px;
  overflow: auto;
  padding: 0 !important;
}

.tool-section {
  margin-bottom: 16px;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.tool-item {
  display: flex;
  align-items: center;
}

.tool-icon {
  width: 16px;
  height: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.tool-icon .anticon {
  color: white;
  font-size: 12px;
}

.tool-name {
  font-size: 14px;
  color: #333;
}
.custom-node .tool-node .ant-menu {
  background-color: transparent;
  border-right: none;
  padding: 0;
  margin: 0;
}
.custom-node .tool-node .ant-menu-vertical {
  border: 0;
}

.custom-node.tool-node .ant-menu-item {
  height: 40px;
  line-height: 40px;
}

.custom-node.tool-node .ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
}

.custom-node .tool-node .ant-menu {
  background-color: transparent;
  border-right: none;
  padding: 0;
  margin: 0;
  width: 220px !important;
}

.custom-node .tool-node .ant-menu-vertical {
  border: 0;
}

.custom-node .tool-node .ant-menu-vertical,
.custom-node .tool-node .ant-menu-vertical-left,
.custom-node .tool-node .ant-menu-vertical-right {
  border-right: none !important;
}

.custom-node .active {
  display: block;
}

.custom-node.tool-node .ant-menu-item {
  height: 40px;
  line-height: 40px;
}

.custom-node.tool-node .ant-menu-item-group-title {
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
}

.custom-node.tool-node .ant-menu,
.custom-node.tool-node .ant-menu-sub,
.custom-node.tool-node .ant-menu-inline {
  border: none !important;
  box-shadow: none !important;
}
.custom-node.llm-node {
  width: 250px;
}

.custom-node.llm-node .node-title {
  margin-bottom: 10px;
}

.custom-node.llm-node .setting-item {
  margin-bottom: 5px;
}

.custom-node.llm-node .setting-item label {
  display: block;
  margin-bottom: 2px;
}

.custom-node.llm-node .ant-select,
.custom-node.llm-node .ant-input,
.custom-node.llm-node .ant-slider {
  width: 100%;
}

.custom-node .llm-node .ant-switch {
  margin-top: 5px;
}

.setting-item {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}

.setting-item label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  width: 100%;
}
.setting-item .element {
  width: 205px;
}

.setting-item .element .ant-input,
.setting-item .element .ant-select,
.setting-item .element .ant-slider {
  width: 205px;
}
.template-editor-item {
  margin-bottom: 16px;
}
.template-editor-item label {
  padding: 5px 0;
  display: block;
}
.template-editor-item .element {
  width: 100% !important;
}
.pii-filter-node {
  width: 250px;
}
.custom-node.intent-node {
  width: 250px;
}

.node-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.node-title .icon {
  margin-right: 10px;
}

.node-desc {
  font-size: 12px;
  margin-bottom: 10px;
}

.node-intents {
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.intent-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  background-color: #eee;
  padding: 3px 10px;
  border-radius: 5px;
}

.intent-name {
  font-size: 12px;
}
.custom-node.intent-node .node-intents .react-flow__handle {
  position: relative;
  left: 25px;
}
.custom-node.knowledge-node {
  width: 250px;
}
.http-sidebar {
}
.http-request-node {
  width: 250px;
}

.custom-handle {
  width: 20px;
  height: 20px;
  position: absolute;
}
.custom-handle.source {
  right: 0px;
}

.custom-handle.target {
  left: 0px;
}

.template-editor-container {
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 10px;
}
.template-editor-container .toolbar {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}
.ant-modal-body .toolbar {
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}
.variable-list-container {
  max-height: 200px;
  overflow-y: auto;
}

.variable-item {
  cursor: pointer;
  padding: 5px 10px;
}

.variable-item:hover {
  background-color: #f0f0f0;
}

.workflow .custom-panel {
  position: absolute;
  width: 600px;
  height: calc(100vh - 146px);
  margin-top: 125px;
  margin-left: 100px;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.workflow .custom-panel .custom-panel-title {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.custom-panel-title-left {
  width: 260px;
  display: flex;
}
.custom-panel-title-left .icon {
  width: 24px;
  height: 24px;
  background-color: #403757;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  align-content: center;
}
.custom-panel-title-left .text {
  display: block;
  margin: 0;
  font-size: 16px;
}
.custom-panel-title-right {
  width: 300px;
  text-align: right;
}
.custom-panel {
  padding: 16px;
}

.custom-panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-panel-title-left {
  display: flex;
  align-items: center;
}

.custom-node.code-node {
  width: 250px;
}
.code-editor-container {
  display: block;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 10px;
  flex-flow: column nowrap;
}
.code-editor-container .toolbar {
  display: flex;
  width: 100%;
  justify-content: end;
}

.studio {
  /* margin-top: 20px; */
}

.operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operation-time {
  color: #666;
  margin-right: 20px;
}
