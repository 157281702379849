.custom-modal {
  &-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    padding: 16px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    display: flex;
    flex-flow: column nowrap;
  }

  &-content {
    flex: 1;
    overflow-y: auto;
  }
}
