.custom-handle {
  width: 12px;
  height: 12px;
  border: 2px solid #eee;
  opacity: 1;
  transition: all 0.3s ease;

  &:hover,
  &:active {
    width: 16px;
    height: 16px;

    .custom-handle-source {
      opacity: 0;
    }
  }

  &-source {
    &::after {
      content: '';
      border-radius: 50%;
      position: absolute;
      display: inline-block;
      width: 14px;
      height: 14px;
      background-image: url('/assets/images/plus.svg');
      background-size: contain;
      background-repeat: no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &:hover::after,
    &:active::after {
      opacity: 1;
      background-color: white;
    }
  }
}
