.condition-branches {
  :global {
    .branch-section {
      border-radius: 14px;
      padding: 18px;
      margin: 8px 0;
      background-color: #f7f7f7;

      .branch-else {
        padding: 20px !important;
      }
    }
  }
}
