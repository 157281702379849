.branch-title {
  padding: 10px 0;
}

.condition-select {
  display: flex;
  flex-direction: column;
  width: 50px;
  border-left: 1px solid #ccc;
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 6px;

  :global {
    .logic-select {
      width: 70px;
      position: absolute;
      transform: translateX(-50%);
      background-color: #f7f7f7;
      border: 0;
      margin-left: 48px;
      box-shadow: none;
    }
  }
}

.form-list {
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 8px;
  overflow: auto;
}


