.custom-edge {
  position: absolute;
  pointer-events: all;

  &-label-mid {
    border-radius: 50%;
    background-color: white;
    color: #a3a3a3;

    &:hover {
      color: black;
    }
  }
}
