.knowledge-list .knowledge-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 210px;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
  margin: 20px 0;
}

.knowledge-card:hover {
  box-shadow: 0 4px 8px #eee;
}

.knowledge-card .knowledge-model {
  display: flex;
  justify-content: right;
  align-items: center;
}

.knowledge-card .knowledge-name {
  font-size: 16px;
  font-weight: bolder;
  padding: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.knowledge-card .knowledge-description {
  font-size: 12px;
  color: #393349;
  margin-bottom: 5px;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.knowledge-card .knowledge-button {
  text-align: center;
}

.knowledge-card .knowledge-button .ant-btn-primary {
  width: 120px;
}
