.organization-page {
  background-color: #fff;
  padding: 32px 16px;
  flex: 1;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: auto;
}

.organization-page .ant-tabs {
  height: 100%;
}

.organization-page .ant-tabs .ant-tabs-nav {
  min-width: 180px;
  overflow: auto;
}

.organization-page .ant-tabs .ant-tabs-content-holder {
  overflow: auto;
}
