.main-layout-header {
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: #fff;
  border-left: 1px solid #eee;
  position: relative;
  min-height: 64px;
}

.main-layout-header .sub-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-layout-header .sub-menu .ant-menu-item-selected .ant-menu-title-content {
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  color: #38304d;
}

.main-layout-header .sub-menu .ant-menu-item-selected::after {
  border: 0;
}

.main-layout-header .sub-menu .ant-menu-item-active::after,
.ant-menu-item::before {
  border: 0 !important;
}

.main-layout-header .sub-menu .ant-menu-horizontal {
  border: 0;
}

.main-layout-header .sub-menu .ant-menu-horizontal > .ant-menu-item::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item:hover::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item-active::after,
.main-layout-header .sub-menu .ant-menu-horizontal > .ant-menu-item-open::after,
.main-layout-header
  .sub-menu
  .ant-menu-horizontal
  > .ant-menu-item-selected::after {
  border-bottom: none !important;
}

.main-layout-header .side-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-left: auto;
}

.main-layout-header .side-menu .hi-message {
  position: relative;
  top: 2px;
  font-size: 14px;
  color: #333;
}

.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover:has(.deselected-item) {
  background-color: transparent !important;
  cursor: unset;
}
