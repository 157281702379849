.knowledge-setting-item {
  background: #fafafa;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.search-by-component .search-by-item label {
  width: 60px;
}

.search-by-component .search-by-type {
  width: 120px;
}

.search-by-component .search-by-data {
  flex: 1;
}

.search-by-component
  .search-by-item
  .search-by-data
  .ant-tree-select
  .ant-select-selection-item {
  width: 120px;
}
