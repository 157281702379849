.node-sidebar {
  &-wrapper {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 450px;
    height: 90%;
    padding-bottom: 8px;
  }

  &-content-wrapper {
    margin: 8px 0;
    padding: 0 12px 0 8px;
  }
}
