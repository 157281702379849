.chunk-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.chunk-list .chunk-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 210px;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: none;
  transition: box-shadow 0.3s ease-in-out;
}

.chunk-card:hover {
  box-shadow: 0 4px 8px #eee;
}

.chunk-card .chunk-content {
  font-size: 12px;
  color: #393349;
  margin: 12px 0;
  width: 260px;
  height: 38px;
  flex: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.chunk-card .chunk-button {
  text-align: center;
}

.chunk-card .chunk-button .ant-btn-primary {
  width: 120px;
}
