@import url('./normalize.css');
@import url('./markdown.css');

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

html {
  background-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f3f3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100vh;
}

.app-wrapper {
  height: 100%;
}

@keyframes moveBackground {
  0% {
    background-position: -200px -200px;
  }
  10% {
    background-position: -300px -250px;
  }
  20% {
    background-position: -450px -430px;
  }
  30% {
    background-position: -500px -480px;
  }
  40% {
    background-position: -350px -320px;
  }
  50% {
    background-position: -600px -600px;
  }
  60% {
    background-position: -550px -500px;
  }
  70% {
    background-position: -400px -450px;
  }
  80% {
    background-position: -300px -350px;
  }
  90% {
    background-position: -250px -300px;
  }
  100% {
    background-position: -200px -200px;
  }
}

.forms .ant-form-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  height: 150px;
  border: 1px solid #e5e5e5;
}

.forms .ant-form-item .ant-input-outlined {
  border: 0;
}

.forms .ant-form-item .ant-input-number {
  border: 0;
}

.forms .ant-select-selector {
  border: 0 !important;
}

.forms .ant-upload {
  width: 80px !important;
  height: 80px !important;
}

.pagination {
  padding: 20px 0;
}

.chat {
  display: flex;
  padding: 20px 0;
  border-radius: 20px;
  background-color: #fff;
  margin-top: 20px;
  height: calc(100vh - 200px);
}

.chat .side {
  width: 250px;
  padding: 20px;
  border-right: 1px solid #f3f3f3;
}

.chat .side .sessions {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 20px;
}

.chat .side .sessions .ant-menu {
  border: none;
}

.chat .side .new_chat_button {
  width: 100%;
}

.chat .side .sessions .ant-menu-item-selected {
  background-color: #f3f3f3;
}
.chat .side .sessions .ant-menu-item-selected .ant-menu-title-content {
  color: #000;
}

.chat .chatbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.chat .chatbox .content {
  width: 90%;
  flex-grow: 1;
  overflow: auto;
  margin: 20px auto;
}

.chat .chatbox .inputbar {
  display: flex;
  width: 90%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;
}

.chat .chatbox .inputbar .input {
  width: 100%;
}

.chat .chatbox .inputbar .input .ant-input {
  border: none;
}

.chat .chatbox .inputbar .input .ant-input-outlined:focus-within {
  border: none;
  box-shadow: none;
}

.chat .chatbox .inputbar .send_button {
  width: 50px;
  height: 40px;
}

.chat .chatbox .dialog_box {
  display: flex;
  padding: 10px 0;
  padding-right: 10px;
}

.chat .chatbox .dialog_box .avatar {
  width: 35px;
  display: block;
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.chat .chatbox .dialog_box .user {
  font-size: 16px;
  padding: 5px 0;
  font-weight: bolder;
}

.chat .chatbox .dialog_box pre {
  overflow-x: auto;
  border: 1px solid #eee;
  background-color: #fdfdfd;
  border-radius: 10px;
}

.chat .chatbox .dialog_box code {
  /* background-color: #eee; */
  border-radius: 10px;
}

.chat .chatbox .dialog_box pre,
.chat .chatbox .dialog_box strong {
  color: #38304d;
}
.chat .chatbox .dialog_box .statusbar {
  width: 300px;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #f3f3f353;
  font-size: 12px;
  padding: 5px;
  color: #000000a1;
  margin-bottom: 10px;
}
.form_wrap {
  display: flex;
  justify-content: flex-start;
}

.form_wrap .form_menu {
  width: 250px;
  flex-shrink: 0;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
}

.form_wrap .form_menu .ant-menu-root {
  border-inline-end: none;
}

.form_wrap .form_menu .ant-menu-item-selected {
  background-color: #f3f3f3;
}

.form_wrap .form_menu .ant-menu-item-selected .ant-menu-title-content,
.form_wrap .form_menu .ant-menu-item-selected .ant-menu-item-icon {
  color: #000 !important;
}

.form_wrap .forms {
  flex-grow: 1;
  width: 0;
  padding-left: 20px;
}

.file-table {
  margin-top: 20px;
}

.dataset {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dataset .dataset_list {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
}

.dataset .dataset_list .dataset_title_box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.dataset .dataset_list .dataset_title_box .dataset_title {
  font-size: 18px;
  line-height: 32px;
  height: 32px;
}

.dataset .dataset_list .dataset_list_items {
  padding: 10px 0;
}

.dynamic-form-rendering .ant-form-item-explain-error {
  color: #ff4d4f !important;
}

.dynamic-form select {
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  padding: 0 11px;
}

.dynamic-form select:hover {
  border-color: #565366;
}

.api_forms {
  flex-grow: 1;
  width: 0;
  padding-left: 20px;
}

.api_forms .basic .ant-form-item {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  height: 150px;
}

.api_forms .basic .ant-form-item .ant-input-outlined {
  border: 0;
}

.api_forms .basic .ant-form-item .ant-input-number {
  border: 0;
}

.api_forms .basic .ant-select-selector {
  border: 0 !important;
}

.api_forms .dynamic {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.api_forms .dynamic .ant-form-item {
  margin-bottom: 0;
}

.code-editor-container {
  position: relative;
  display: flex;
  border-radius: 4px;
  font-family: 'Fira code', 'Fira Mono', monospace;
  font-size: 12px;
  margin-bottom: 16px;
  background-color: #fff;
  overflow: 'auto';
  flex-flow: column nowrap;
}

.line-numbers {
  padding: 0 10px;
  text-align: right;
  color: rgba(0, 0, 0, 0.3);
  padding-right: 10px;
  user-select: none;
  overflow: hidden;
}

.code-editor {
  flex: 1;
  padding: 10px;
  overflow: auto;
  outline: none;
  border: none;
  box-sizing: border-box;
}

.code-editor textarea {
  border: none;
}

.code-editor textarea:focus {
  outline: none;
}

.llm-node {
  width: 700px;
}
